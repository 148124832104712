
import {Vue, Component, Watch} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import Http from '@/Http';

@Component({})
export default class TransportCreateDialog extends Vue {

  form = new Form();
  errors = new Errors();

  view = false;
  loading = false;

  created() {
    AppStore.onTransportOpenCreate(() => {
      this.view = true;
      this.form = new Form();
      this.errors = new Errors();
    });
  }

  changeRegNumber() {
    const maxLen = this.form.isForeign === 0 ? 9 : 10;
    const pattern = this.form.isForeign === 0 ? /[^а-я0-9]/gi : /[^a-z0-9]/gi;

    setTimeout(() => {
      let val = this.form.regNumber;
      val = val.replace(pattern, '');
      if (val.length > maxLen) {
        val = val.substring(0, maxLen);
      }
      this.form.regNumber = val.toUpperCase();
    });
  }

  changeVin() {
    setTimeout(() => {
      let val = this.form.vin;
      val = val.replace(/[^a-z0-9]/gi, '');
      if (val.length > 17) {
        val = val.substring(0, 17);
      }
      this.form.vin = val.toUpperCase();
    }, 50);
  }


  submit() {


    this.loading = true;

    Http.post('/v2/transports/create', this.form)
        .then((response: any) => {
          this.view = false;
          AppStore.transportUpdateFilter();
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((HttpError: any) => {
          switch (HttpError.error) {
            case 'parameters_invalid':
              HttpError.errors.forEach((item: any) => {
                this.errors[item.field] = item.message;
              });
              break;
            default:
              Http.systemError(HttpError.error);
          }
        });
  }

}

class Form {
  regNumber = '';
  vin = '';
  stsNumber = '';
  isForeign = 0;
  brand = '';
  model = '';

  constructor(init?: Partial<Form>) {
    Object.assign(this, init);
  }
}

class Errors {
  regNumber = '';
  vin = '';
  stsNumber = '';
  isForeign = '';
  brand = '';
  model = '';

  [key: string]: string;
}

