
import {Vue, Component} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import TransportV2 from '@/components/models/TransportV2';
import Http from '@/Http';

@Component({})
export default class TransportRemoveDialog extends Vue {

  loading = false;
  view = false;
  transport = new TransportV2();

  mounted() {
    AppStore.onTransportOpenRemove((transport) => {
      this.view = true;
      this.transport = transport;
    });
  }

  close() {
    this.view = false;
  }

  remove() {
    this.loading = true;
    Http.post('/v2/transports/remove', {transportId: this.transport.transportId})
        .then((value: any) => {
          this.close();
          AppStore.transportCloseEdit();
          AppStore.transportUpdateFilter();
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((HttpError) => {
          Http.systemError(HttpError.error);
        });
  }

}
