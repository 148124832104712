
import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import Transport from '@/components/models/Transport';

@Component({})
export default class PassesSearch extends Vue {

  @Model('setTransports')
  transports!: Transport[];

  @Prop()
  transportsAll!: Transport[];

  search = '';

  @Watch('search')
  searchWatch() {
    let results: any[] = [];
    this.transportsAll.forEach((t) => {
      const tt = Object.assign({}, t);
      delete tt.rnis;
      if (JSON.stringify(tt).toUpperCase().indexOf(this.search.toUpperCase().trim()) !== -1) {
        results.push(t);
      }
    });

    if (this.search.length <= 0) {
      results = this.transportsAll;
    }

    this.$emit('setTransports', results);
  }

  @Watch('transportsAll')
  transportsAllWatch() {
    this.search = '';
  }

}
