
import {Component, Vue, Watch} from 'vue-property-decorator';
import Transport from '@/components/models/Transport';
import AppStore from '@/components/AppStore';
import Http from '@/Http';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import ManagerBlock from '@/components/ManagerBlock.vue';
import RequestNewPass from '@/components/RequestNewPass.vue';
import MenuTop from '@/components/MenuTop.vue';
import ExportExcel from '@/components/ExportExcel.vue';
import PassesSearch from '@/components/PassesSearch.vue';
import TransportFilter from '@/components/transports/TransportFilter';
import {debounceTime} from '@/helpers/EventHelper';
import TransportModeTable from '@/components/transports/TransportModeTable.vue';
import TransportModeBlock from '@/components/transports/TransportModeBlock.vue';
import TransportCreateDialog from '@/components/transports/TransportCreateDialog.vue';
import TransportEditDialog from '@/components/transports/TransportEditDialog.vue';
import TransportRemoveDialog from '@/components/transports/TransportRemoveDialog.vue';

@Component({
  components: {
    TransportRemoveDialog,
    TransportEditDialog,
    TransportCreateDialog,
    TransportModeBlock,
    TransportModeTable,
    PassesSearch,
    ExportExcel,
    MenuTop,
    RequestNewPass,
    ManagerBlock,
    AppTopBar
  }
})
export default class TransportView extends Vue {

  transports: Transport[] = [];

  displayMode = AppStore.displayMode;
  containerWidth = AppStore.containerWidth;

  filter = new TransportFilter();

  search = '';

  @Watch('search')
  searchWatch() {
    AppStore.showLoader();
    this.filter.query = this.search;
    debounceTime(() => {
      this.updateFilter();
    });
  }


  updateFilter() {
    AppStore.transportUpdateFilter(this.filter);
  }

  load() {
    AppStore.showLoader();
    Http.post('/v2/transports', this.filter)
        .then((value: any) => {
          this.transports = value;
        })
        .finally(() => {
          AppStore.hideLoader();
        })
        .catch((HttpError) => {
          switch (HttpError.error) {
            default:
              Http.systemError(HttpError.error);
          }
        });
  }

  mounted() {

    AppStore.onTransportUpdateFilter((filter) => {
      this.filter = filter;
      this.load();
    });

    AppStore.onDisplayMode(v => this.displayMode = v);
    AppStore.onContainerWidth(v => this.containerWidth = v);

    this.load();

  }

  beforeDestroy() {
    AppStore.off();
  }

}


