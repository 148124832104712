
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DiagnosticCardStatusChip from '@/components/dcs/DiagnosticCardStatusChip.vue';
import ModeTableStatusIcon from '@/components/ModeTableStatusIcon.vue';
import Pass from '@/components/models/Pass';
import PassHelper from '@/components/PassHelper';
import TransportV2, {RnisStatusEnum} from '@/components/models/TransportV2';
import TransportModeTableCancelTd from '@/components/transports/TransportModeTableCancelTd.vue';
import AppStore from '@/components/AppStore';

@Component({
  components: {TransportModeTableCancelTd, ModeTableStatusIcon, DiagnosticCardStatusChip}
})
export default class TransportModeTable extends Vue {

  @Prop()
  transports!: TransportV2[];
  items: TransportItem[] = [];
  tdsClass = [];
  passHelper: any = PassHelper;

  RnisStatusEnum: RnisStatusEnum | any = RnisStatusEnum;

  @Watch('transports')
  transportsWatch() {
    this.formatTransports();
  }

  mounted() {
    this.formatTransports();
  }

  formatTransports() {
    const out: TransportItem[] = [];
    this.transports.forEach(v => {

      const t = new TransportItem(v);

      t.passDay = this.getActualPass(t, 'DAY');
      t.passNight = this.getActualPass(t, 'NIGHT');

      out.push(t);

    });

    this.items = out;
  }

  getActualPass(
      transport: TransportV2,
      timeOfDay: 'DAY' | 'NIGHT'
  ): Pass {

    let BAEnd;
    let BBEnd;
    let BACancel;
    const pass = new Pass();

    if (timeOfDay === 'DAY') {
      BAEnd = transport.passDayBAEndDate;
      BACancel = transport.passDayBACancelDate;
      BBEnd = transport.passDayBBEndDate;
    } else {
      BAEnd = transport.passNightBAEndDate;
      BACancel = transport.passNightBACancelDate;
      BBEnd = transport.passNightBBEndDate;
    }

    const BADay = PassHelper.calcLeftDays(BAEnd);
    const BBDay = PassHelper.calcLeftDays(BBEnd);

    if ((!BADay || BACancel) && BBDay) {
      pass.leftDays = BBDay;

      if (timeOfDay === 'DAY') {
        pass.number = transport.passDayBBNumber;
        pass.startDate = transport.passDayBBStartDate;
        pass.endDate = transport.passDayBBEndDate;
        pass.allowedZone = transport.passDayBBAllowedZone;
      } else {
        pass.number = transport.passNightBBNumber;
        pass.startDate = transport.passNightBBStartDate;
        pass.endDate = transport.passNightBBEndDate;
        pass.allowedZone = transport.passNightBBAllowedZone;
      }
      return pass;
    }


    if (BADay) {
      pass.leftDays = BADay;

      if (timeOfDay === 'DAY') {
        pass.number = transport.passDayBANumber;
        pass.startDate = transport.passDayBAStartDate;
        pass.endDate = transport.passDayBAEndDate;
        pass.cancelDate = transport.passDayBACancelDate;
        pass.allowedZone = transport.passDayBAAllowedZone;
      } else {
        pass.number = transport.passNightBANumber;
        pass.startDate = transport.passNightBAStartDate;
        pass.endDate = transport.passNightBAEndDate;
        pass.cancelDate = transport.passNightBACancelDate;
        pass.allowedZone = transport.passNightBAAllowedZone;
      }

      return pass;
    }

    return pass;
  }

  calcLeftDays(end: string) {
    const r = this.$moment(end).startOf('day').diff(this.$moment(), 'days');
    if (isNaN(r)) {
      return null;
    }
    if (r <= 0) {
      return null;
    }
    return r;
  }

  openDC(cardNumber: string) {
    if (!cardNumber) {
      return;
    }
    window.open('/dcs#' + cardNumber, '_blank');
  }

  openCreate() {
    AppStore.transportOpenCreate();
  }

  openEdit(item: TransportV2) {
    AppStore.transportOpenEdit(item);
  }

}

class TransportItem extends TransportV2 {
  passDay = new Pass();
  passNight = new Pass();
}


