
import {Component, Vue, Watch} from 'vue-property-decorator';
import Http, {HttpError} from '@/Http';
import AppStore from '@/components/AppStore';
import VTextFieldDatePicker from '@/components/VTextFieldDatePicker.vue';

@Component({
  components: {VTextFieldDatePicker}
})
export default class RequestNewPass extends Vue {
  view = false;
  loading = false;

  form = new Form();
  errors = new Errors();

  @Watch('view')
  viewWatch() {
    if (this.view) {
      this.form = new Form();
      this.errors = new Errors();
      const d = new Date();
      this.form.startDate = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    }
  }

  submit() {
    this.loading = true;
    Http.post('/transports/newPass', this.form).then((value: any) => {
      AppStore.snackbarOpenSuccess('Заявка успешно отправлена вашему менеджеру.');
      this.view = false;
    }).finally(() => {
      this.loading = false;
    }).catch((error: HttpError) => {
      switch (error.error) {
        case 'parameters_invalid':
          error.errors.forEach((item) => {
            this.errors[item.field] = item.message;
          });
          break;
      }
    });
  }
}

class Form {
  regNumber = '';
  isSeriesBB = 0;
  isSeriesBA = 0;
  isDaytime = 0;
  isNightTime = 0;
  allowedZone = 'МКАД';
  startDate = 'МКАД';

  constructor(init?: Partial<Form>) {
    Object.assign(this, init);
  }
}

class Errors {
  regNumber = '';
  isSeriesBB = '';
  isSeriesBA = '';
  isDaytime = '';
  isNightTime = '';
  allowedZone = '';
  startDate = '';

  [key: string]: string;
}

