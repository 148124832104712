import {NullNumber} from '@/types/NullTypes';

export default class Pass {
    series = '';
    number = '';
    allowedZone = '';
    startDate = '';
    endDate = '';
    cancelDate: string | null = null;
    leftDays: NullNumber = null;
}
