
import {Component, Emit, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {debounceTime} from '@/helpers/EventHelper';

@Component({})
export default class VTextFieldDatePicker extends Vue {

  @Prop({required: true})
  label!: string;

  @Prop({default: false})
  readonly!: boolean;

  @Prop({default: false})
  disabled!: boolean;

  @Prop({default: false})
  loading!: boolean;

  @Prop({default: false})
  outlined!: boolean;

  @Prop({default: false})
  messages!: string;

  @Prop({default: false})
  dense!: boolean;

  @Prop()
  errorMessages!: string;

  @Prop({default: false})
  disableIcon!: any;

  @Prop({default: false})
  hideDetails!: string;

  error = '';
  menu = false;
  dateField = '';
  datePicker = '';
  inputDisable = true;

  prependIcon = 'mdi-calendar';

  @Model('setValue')
  value: string | null | undefined;

  @Watch('value')
  valueWatch() {
    if (this.inputDisable) {
      return;
    }
    this.init();
  }

  created() {
    this.init();
  }

  init() {
    this.inputDisable = true;
    this.dateField = '';
    this.$nextTick(() => {
      const m = this.$moment(this.value, 'YYYY-MM-DD');
      if (m.isValid()) {
        this.datePicker = m.format('YYYY-MM-DD');
        this.dateField = m.format('DD.MM.YYYY');
      }
      this.inputDisable = false;
    });
  }

  changeDatePicker() {
    const m = this.$moment(this.datePicker);
    if (m.isValid()) {
      this.dateField = m.format('DD.MM.YYYY');
      this.setValue(m.format('YYYY-MM-DD'));
    }
  }


  fieldInput() {

    if (this.inputDisable) {
      return;
    }

    if (this.dateField.length === 10) {
      const m = this.$moment(this.dateField, 'DD.MM.YYYY');
      if (m.isValid()) {
        this.datePicker = m.format('YYYY-MM-DD');
        this.dateField = m.format('DD.MM.YYYY');
        this.setValue(m.format('YYYY-MM-DD'));
      } else {
        this.error = 'Неверный формат даты.';
        this.setValue(null);
        this.datePicker = '';
      }
    }
    if (this.dateField.length === 0) {
      this.setValue(null);
    }
  }

  @Watch('errorMessages')
  errorMessagesWatch(message: string) {
    this.error = message;
  }

  @Emit('change')
  change() {
  }

  keydown() {
    this.error = '';
    this.$emit('keydown');
  }

  setValue(val: string | null) {
    this.inputDisable = true;
    this.$emit('setValue', val);
    setTimeout(() => {
      this.inputDisable = false;
    }, 500);

    debounceTime(() => {
      this.change();
    }, 100);
  }

}
