// export default class EventHelper {
//
//     public static debounceTime(callback: () => void, time: number = 1000) {
//
//         if (typeof this.timer !== 'undefined') {
//             clearTimeout(this.timer);
//         }
//
//         this.timer = setTimeout(() => {
//             callback();
//         }, time);
//
//     }
//
//     private static timer: number;
// }

let timer: number;

export function debounceTime(callback: () => void, time: number = 1000) {

    if (typeof timer !== 'undefined') {
        clearTimeout(timer);
    }

    timer = setTimeout(() => {
        callback();
    }, time);

}
