import Rnis from '@/components/models/Rnis';
import {NullNumber} from '@/types/NullTypes';

export default class TransportV2 {

    transportId: NullNumber = null;
    isEditable = 0;

    regNumber = '';
    stsNumber = '';
    vin = '';
    brand = '';
    model = '';

    proprietorName = '';

    rnisIsReg: NullNumber = null;
    rnisIsOnline: NullNumber = null;
    rnisLastMark: NullNumber = null;

    passDayStatusId = 0;
    passDayForecastDate = '';

    passDayBANumber = '';
    passDayBAStartDate = '';
    passDayBAEndDate = '';
    passDayBACancelDate = '';
    passDayBAAllowedZone = '';

    passDayBBNumber = '';
    passDayBBStartDate = '';
    passDayBBEndDate = '';
    passDayBBAllowedZone = '';

    passNightStatusId = 0;
    passNighForecastDate = '';

    passNightBANumber = '';
    passNightBAStartDate = '';
    passNightBAEndDate = '';
    passNightBACancelDate = '';
    passNightBAAllowedZone = '';

    passNightBBNumber = '';
    passNightBBStartDate = '';
    passNightBBEndDate = '';
    passNightBBAllowedZone = '';

    dcNumber = '';
    dcIssueDate = '';
    dcExpiryDate = '';
    dcOdometer = 0;
    dcOperatorNumber: NullNumber = null;
    dcOperatorStatus = '';

    updatedAt = '';
    createdAt = '';

    rnis: Rnis | null = null;

    rnisStatus: RnisStatusEnum = RnisStatusEnum.InQueueForCheck;

    static getRnisStatuses() {
        const out: any[] = [];
        Object.values(RnisStatusEnum).forEach((v) => {
            out.push({value: v, text: TransportV2.getRnisLabel(v)});
        });
        return out;
    }

    static getRnisColor(status: RnisStatusEnum) {
        switch (status) {
            case RnisStatusEnum.InQueueForCheck:
                return 'grey';
            case RnisStatusEnum.NotRegistered:
                return 'red';
            case RnisStatusEnum.RegisteredTelemetryTransmitted:
                return 'green';
            case RnisStatusEnum.RegisteredTelemetryNotTransmitted:
                return 'orange';
        }
    }

    static getRnisLabel(status: RnisStatusEnum) {
        switch (status) {
            case RnisStatusEnum.InQueueForCheck:
                return 'В очереди на проверку';
            case RnisStatusEnum.NotRegistered:
                return 'Не зарегистрирована';
            case RnisStatusEnum.RegisteredTelemetryTransmitted:
                return 'Зарегистрирована, телематические данные передаются';
            case RnisStatusEnum.RegisteredTelemetryNotTransmitted:
                return 'Зарегистрирована, телематические данные не передаются';
        }
    }

    constructor(init?: Partial<TransportV2>) {
        Object.assign(this, init);
    }
}


export enum RnisStatusEnum {
    InQueueForCheck = 'IN_QUEUE_FOR_CHECK',
    NotRegistered = 'NOT_REGISTERED',
    RegisteredTelemetryTransmitted = 'REGISTERED_TELEMETRY_TRANSMITTED',
    RegisteredTelemetryNotTransmitted = 'REGISTERED_TELEMETRY_NOT_TRANSMITTED',
}

