
import {Vue, Component} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import TransportV2 from '@/components/models/TransportV2';

@Component({})
export default class TransportEditDialog extends Vue {

  view = false;
  transport = new TransportV2();

  mounted() {
    AppStore.onTransportOpenEdit((transport) => {
      this.view = true;
      this.transport = transport;
    });

    AppStore.onTransportCloseEdit(() => this.view = false);
  }

  openRemove() {
    AppStore.transportOpenRemove(this.transport);
  }

}
