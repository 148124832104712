
import {Component, Prop, Vue} from 'vue-property-decorator';
import {NullString} from '@/types/NullTypes';

@Component({})
export default class TransportModeTableCancelTd extends Vue {

  @Prop()
  cancelDate!:NullString;

  @Prop()
  data!:NullString;

}
