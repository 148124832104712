
import {Component, Prop, Vue} from 'vue-property-decorator';
import PassBlock from '@/components/PassBlock.vue';
import TransportFilter from '@/components/transports/TransportFilter';
import AppStore from '@/components/AppStore';
import TransportV2 from '@/components/models/TransportV2';
import TransportModeBlockPass from '@/components/transports/TransportModeBlockPass.vue';

@Component({
  components: {TransportModeBlockPass, PassBlock}
})
export default class TransportModeBlock extends Vue {

  @Prop()
  transports!: TransportV2[];
  filter = new TransportFilter();

  TransportV2: TransportV2 | any = TransportV2;

  sortChange(e: string) {
    this.filter.order = e;
    AppStore.transportUpdateFilter(this.filter);
  }

  mounted() {

    AppStore.onTransportUpdateFilter((filter) => {
      this.filter = filter;
    });

  }

}
