
import {Vue, Component, Prop} from 'vue-property-decorator';
import VMaskPhone from '@/UI/VMaskPhone.vue';

@Component({
  components: {VMaskPhone}
})
export default class ManagerWrapper extends Vue {

  @Prop({required: true})
  loading!: boolean;

  @Prop({required: true})
  title!: string;

  @Prop({required: true})
  label!: string;

  @Prop({})
  avatar!: string;

  @Prop({})
  phone!: string;

  @Prop({})
  email!: string;

}
